import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Sahte bir getQuestionData fonksiyonu oluşturuyoruz
const getQuestionData = async (id) => {
  // Bu, API'den veri alıyormuş gibi bir bekleme simülasyonu
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ question: "Bu bir soru örneğidir", details: "Bu soru hakkında daha fazla detay buraya yazılabilir." });
    }, 1000); // 1 saniye sonra veriyi döndürüyor
  });
};

const QuestionPage = () => {
  const { id } = useParams();
  const [questionData, setQuestionData] = useState(null);

  useEffect(() => {
    const fetchQuestionData = async () => {
      const data = await getQuestionData(id);
      setQuestionData(data);
    };
    fetchQuestionData();
  }, [id]);

  return (
    <div>
      <h1>Soru Detayı</h1>
      {questionData ? (
        <div>
          <p>{questionData.question}</p>
          <p>{questionData.details}</p>
        </div>
      ) : (
        <p>Yükleniyor...</p>
      )}
    </div>
  );
};

export default QuestionPage;
