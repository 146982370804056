import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from '../firebase'; // Import Firestore and auth
import { doc, setDoc, getDoc, query, collection, where, getDocs } from 'firebase/firestore'; // Firestore functions
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'; // CSS dosyasını unutmayın

// Function to generate a random username
const generateRandomUsername = () => {
  const randomNum = Math.floor(1000 + Math.random() * 9000); // Generates a random 4-digit number
  return `user${randomNum}`;
};

// Function to check if a username exists and generate a unique one if necessary
const checkUsernameExists = async (username) => {
  const q = query(collection(db, 'users'), where('username', '==', username));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
};

const generateUniqueUsername = async () => {
  let username;
  let exists = true;

  while (exists) {
    username = generateRandomUsername();
    exists = await checkUsernameExists(username);
  }

  return username;
};

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Function to check and create user in Firestore
  const checkAndCreateUser = async (user) => {
    const userDocRef = doc(db, 'users', user.uid); // Reference to the user document in Firestore
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      // Generate a unique username for the user
      const randomUsername = await generateUniqueUsername();

      // Create a new document with the random username
      await setDoc(userDocRef, {
        uid: user.uid,
        email: user.email,
        username: randomUsername,  // Assign random username
        displayName: user.displayName || 'Anonymous',
        photoURL: user.photoURL || '',
        createdAt: new Date(),
      });
      console.log('New user created in Firestore with random username:', randomUsername);
    } else {
      console.log('User already exists in Firestore.');
    }
  };

  // Email and password login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      await checkAndCreateUser(user); // Check and save user if first login
      
      navigate('/'); // Redirect to homepage on successful login
    } catch (error) {
      setError('Giriş başarısız. Lütfen bilgilerinizi kontrol edin.');
    }
  };

  // Google login
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      await checkAndCreateUser(user); // Check and save user if first login

      navigate('/'); // Redirect to homepage on successful login
    } catch (error) {
      setError('Google ile giriş başarısız.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Giriş Yap</h2>
        {error && <p className="error-message">{error}</p>}

        {/* Email ve Şifre ile Giriş */}
        <form onSubmit={handleLogin}>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Email" 
            className="input-field"
            required 
          />
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Şifre" 
            className="input-field"
            required 
          />
          <button type="submit" className="login-button">Giriş Yap</button>
        </form>

        <p className="divider">veya</p>

        {/* Google ile Giriş */}
        <button onClick={handleGoogleLogin} className="google-login-button">
          <img src="icons/google-icon.svg" alt="Google Icon" className="google-icon" />
          Google ile Giriş Yap
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
