import React, { useEffect, useState } from 'react';

// Sahte bir getUserData fonksiyonu oluşturuyoruz
const getUserData = async () => {
  // Bu, API'den veri alıyormuş gibi bir bekleme simülasyonu
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ name: "John Doe", email: "johndoe@example.com" });
    }, 1000); // 1 saniye sonra veriyi döndürüyor
  });
};

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserData();
      setUserData(data);
    };
    fetchData();
  }, []);

  return (
    <div>
      <h1>Profil Sayfası</h1>
      {userData ? (
        <div>
          <p>Ad: {userData.name}</p>
          <p>Email: {userData.email}</p>
        </div>
      ) : (
        <p>Yükleniyor...</p>
      )}
    </div>
  );
};

export default ProfilePage;
