import React, { useState, useEffect } from 'react'; // useState ve useEffect'i React'ten import ettik
import { auth, db } from '../firebase'; // Firebase'den auth ve db'yi import ediyoruz
import { doc, getDoc, addDoc, collection, query, orderBy, onSnapshot } from 'firebase/firestore'; // Firestore işlemleri
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage işlemleri
import { signOut } from 'firebase/auth'; // Firebase'den signOut fonksiyonunu import ediyoruz
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faVideo, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth'; // Import Firebase auth listener
import './HomePage.css';

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [question, setQuestion] = useState(''); // Soru state'i
  const [media, setMedia] = useState(null); // Medya (resim/video) state'i
  const [questions, setQuestions] = useState([]); // Tüm soruları saklamak için state
  const storage = getStorage(); // Firebase Storage referansı
  // const [user, setUser] = useState(null);  // Kullanıcı bilgilerini tutacak state
// const [loading, setLoading] = useState(true);  // Yükleme durumunu tutacak state

 useEffect(() => {
    const fetchUserData = async () => {
      const unsubscribe1 = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Kullanıcının Firestore'daki bilgilerini almak
        const fetchUserData = async () => {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data()); // Verileri state'e kaydediyoruz
        }
      };
      fetchUserData();
      }
      else{
        navigate('/login'); 
      }
      return () =>unsubscribe1();
      });
    };

    fetchUserData();

    // Soruları Firestore'dan çekme ve dinamik olarak güncelleme
    const q = query(collection(db, 'questions'), orderBy('createdAt', 'desc')); // Soruları tarihe göre sırala
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const questionsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setQuestions(questionsData); // Soruları state'e kaydet
    });

    // Unsubscribe ile dinleyiciyi temizleme
    return () => {
      unsubscribe();
      
    };
    
  },  [navigate]); ;

  const handleLogout = async () => {
    try {
      await signOut(auth); // Firebase'den çıkış yapıyoruz
      setUserData(null); // Kullanıcı bilgilerini sıfırlıyoruz
      console.log('Çıkış yapıldı.');
    } catch (error) {
      console.error('Çıkış hatası:.', error);
    }
  };

  // Soru kaydetme işlemi
  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    if (!question.trim()) {
      alert('Lütfen bir soru yazın.');
      return;
    }

    let mediaURL = null;
    if (media) {
      // Firebase Storage'a dosya yükleme
      const storageRef = ref(storage, `media/${media.name}`);
      await uploadBytes(storageRef, media);
      mediaURL = await getDownloadURL(storageRef);
    }

    // Soruyu Firestore'a kaydetme
    await addDoc(collection(db, 'questions'), {
      uid: userData.uid,
      username: userData.username,
      question,
      mediaURL,
      createdAt: new Date()
    });

    // Formu sıfırlama
    setQuestion('');
    setMedia(null);
    alert('Soru başarıyla kaydedildi.');
  };

  // Medya dosyası ekleme
  const handleMediaChange = (e) => {
    if (e.target.files[0]) {
      setMedia(e.target.files[0]); // Seçilen dosyayı state'e kaydediyoruz
    }
  };

  const goToLogin = () => {
    navigate('/login');
  };

  // Dil değiştirme fonksiyonu
  function changeLanguage(lng) {
    i18n.changeLanguage(lng);
  }

  return (
    <div className="homepage-container">
      {/* Dil değiştirme butonları */}
      <div className="language-switcher">
        <button onClick={() => changeLanguage('en')}>English</button>
        <button onClick={() => changeLanguage('tr')}>Türkçe</button>
      </div>

      {/* Sol Menü */}
      <nav className="sidebar">
        <ul>
          <li>{t('home')}</li>
          <li>{t('explore')}</li>
          <li>{t('notifications')}</li>
          <li>{t('messages')}</li>
          <li>{t('bookmarks')}</li>
          <li>{t('profile')}</li>
          <li>{t('more')}</li>
        </ul>
      </nav>

      {/* Orta İçerik */}
      <main className="main-content">
        <div className="login-icon-container">   

          {userData && (
            <div className="user-info">
              <img src={userData.photoURL} alt="Profil" className="profile-picture" />
              <div className="user-details">
                <p className="username">@{userData.username}</p>
                <p>{userData.displayName}</p>
              </div>
              <button onClick={handleLogout} className="logout-button">
                <FontAwesomeIcon icon={faSignOutAlt} /> Çıkış Yap
              </button>
            </div>
          )}

          {/* Giriş Butonu - Kullanıcı giriş yapmadıysa görünür */}
          {!userData && (
            <div className="login-icon-container">
              <button onClick={goToLogin} className="login-button">
                <FontAwesomeIcon icon={faSignInAlt} /> Giriş Yap.
              </button>
            </div>
          )}

        </div>

        {/* Soru Sorma Alanı */}
        {userData ? (
          <form onSubmit={handleQuestionSubmit} className="question-box">
            <textarea
              placeholder={t('ask_question')}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <div className="icon-bar">
              {/* Resim ve Video Ekleme İkonları */}
              <label>
                <FontAwesomeIcon icon={faImage} className="icon" title={t('add_image')} />
                <input type="file" accept="image/*" onChange={handleMediaChange} style={{ display: 'none' }} />
              </label>
              <label>
                <FontAwesomeIcon icon={faVideo} className="icon" title={t('add_video')} />
                <input type="file" accept="video/*" onChange={handleMediaChange} style={{ display: 'none' }} />
              </label>
            </div>
            <button type="submit">{t('send')}</button>
          </form>
        ) : (
          <p>Lütfen giriş yaparak soru sorunuz.</p>
        )}

        {/* Soruların Listesi */}
        <div className="feed">
          {questions.map((q) => (
            <div key={q.id} className="post">
              <p><strong>@{q.username}</strong>: {q.question}</p>
              {q.mediaURL && (
                <>
                  {q.mediaURL.endsWith('.mp4') ? (
                    <video src={q.mediaURL} controls className="post-media" />
                  ) : (
                    <img src={q.mediaURL} alt="Soru Medyası" className="post-media" />
                  )}
                </>
              )}
              <div className="post-actions">
                <button>Beğen</button>
                <button>Merak Ediyorum</button>
                <button>İstişare</button>
              </div>
            </div>
          ))}
        </div>
      </main>

      {/* Sağ Menü */}
      <aside className="trends">
        <h2>{t('trending')}</h2>
        <ul>
          <li>#teknoloji</li>
          <li>#yapayzeka</li>
          <li>#reactjs</li>
        </ul>
      </aside>
    </div>
  );
};

export default HomePage;
